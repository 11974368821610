import request from "@/utils/request";

export function countHome() {
    return request({
        url: `/pastd/home/count`,
        method: "get",
    });
}

export function chartArea() {
    return request({
        url: `/pastd/home/chart/area`,
        method: "get",
    });
}

export function chartSpe() {
    return request({
        url: `/pastd/home/chart/spe`,
        method: "get",
    });
}

export function chartDay() {
    return request({
        url: `/pastd/home/chart/day`,
        method: "get",
    });
}